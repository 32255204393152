import {
    IBusinessmanProfile,
    IBusinessmanProfilePublic,
    IDfaEmitterInfo,
    ILegalEntityProfile,
    ILegalEntityProfilePublic,
    ProfileType,
} from '@dltru/dfa-models'
import dayjs from 'dayjs'

import { EMPTY_FIELD_VALUE, VIEW_DATE_FORMAT } from '../../../constants'

export const getEmitterData = (
    emitterTypes: ProfileType[],
    emitterData?:
        | ILegalEntityProfilePublic
        | IBusinessmanProfilePublic
        | ILegalEntityProfile
        | IBusinessmanProfile,
) => {
    if (!emitterData) {
        return undefined
    }
    const isIp = emitterTypes.includes(ProfileType.BUSN)
    if (isIp) {
        let data
        if ('business_info' in emitterData && 'person' in emitterData) {
            data = { ...emitterData.business_info, ...emitterData.person }
        } else {
            data = emitterData as IBusinessmanProfilePublic
        }
        return {
            types: emitterTypes,
            short_name: `ИП ${data.first_name} ${data.last_name} ${data.second_name}`,
            legal_address: data.registration_address,
            post_address: data.post_address,
            inn: data.inn,
            websites: data.websites ? data.websites : [],
            ogrnip: data.ogrn,
            ogrn_date: data.ogrn_date,
        }
    }

    const data = emitterData as ILegalEntityProfile | ILegalEntityProfilePublic
    const title = 'title' in data ? `${data.title}` : ''

    return {
        types: emitterTypes,
        short_name: title,
        full_name: title,
        legal_address: data.legal_address,
        post_address: 'post_address' in data ? data.post_address : '',
        inn: data.inn,
        kpp: 'kpp' in data ? data.kpp : '',
        websites: data.websites ?? [],
        ogrn: data.ogrn,
        ogrn_date: data.ogrn_date,
    }
}

export const dataForInfoCard = (data?: IDfaEmitterInfo | null) => {
    const isIp = data?.types?.includes(ProfileType.BUSN)
    const websites = data?.websites?.length
        ? data.websites?.map((site, idx) => ({
              label: idx ? '' : 'Сайт',
              value: (
                  <a href={site} rel="noreferrer noopener" target="_blank">
                      {site}
                  </a>
              ),
          }))
        : [{ label: 'Сайт', value: EMPTY_FIELD_VALUE }]
    return isIp
        ? {
              heading: data?.short_name || EMPTY_FIELD_VALUE,
              data: [
                  {
                      label: 'Место жительства',
                      value: data?.legal_address || EMPTY_FIELD_VALUE,
                  },
                  { label: 'Почтовый адрес', value: data?.post_address || EMPTY_FIELD_VALUE },
                  {
                      label: 'ОГРНИП',
                      value: data?.ogrnip || EMPTY_FIELD_VALUE,
                  },
                  { label: 'ИНН', value: data?.inn || EMPTY_FIELD_VALUE },
                  {
                      label: 'Дата регистрации',
                      value: data?.ogrn_date
                          ? dayjs.unix(data.ogrn_date).format(VIEW_DATE_FORMAT)
                          : EMPTY_FIELD_VALUE,
                  },
                  ...websites,
              ],
          }
        : {
              heading: data?.short_name || EMPTY_FIELD_VALUE,
              data: [
                  {
                      label: 'Полное наименование',
                      value: data?.full_name || EMPTY_FIELD_VALUE,
                  },
                  {
                      label: 'Юридический адрес',
                      value: data?.legal_address || EMPTY_FIELD_VALUE,
                  },
                  { label: 'Почтовый адрес', value: data?.post_address || EMPTY_FIELD_VALUE },
                  {
                      label: 'ОГРН',
                      value: data?.ogrn || EMPTY_FIELD_VALUE,
                  },
                  { label: 'ИНН', value: data?.inn || EMPTY_FIELD_VALUE },
                  { label: 'КПП', value: data?.kpp || EMPTY_FIELD_VALUE },
                  {
                      label: 'Дата регистрации',
                      value: data?.ogrn_date
                          ? dayjs.unix(data.ogrn_date).format(VIEW_DATE_FORMAT)
                          : EMPTY_FIELD_VALUE,
                  },
                  ...websites,
              ],
          }
}
